import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header"

const Layout = ({ children, isHeaderFixed }) => {
  return (
    <div className="antialiased font-body bg-white">
      <Header isFixed={isHeaderFixed} />
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

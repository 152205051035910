import React from "react"
import { Link } from "gatsby"

import Annwn from "../svgs/annwn.svg"

const Footer = () => {
  return (
    <div id="footer" className="bg-voilet-900 text-white">
      <div className="w-full px-4 lg:px-16 pt-8 lg:pt-16 pb-4">
        <div className="flex flex-wrap text-white justify-between md:px-16">
          <div className="mx-4 flex items-baseline mt-2 w-full md:w-auto">
            <ul className="md:mx-auto">
              <Link to="/" className="flex items-center">
                <Annwn
                  className="h-10 w-auto sm:h-12 text-white"
                  alt="Annwn Tech"
                />
                <div className="ml-4 flex md:flex-col">
                  <div className="mr-1 md:mr-0">Annwn Tech</div>
                  <div>Private Limited</div>
                </div>
              </Link>
              <li className="my-4 text-gray-600 text-sm">
                &copy;2020, All rights reserved
              </li>
            </ul>
          </div>
          <div className="mx-4 flex items-baseline mt-2">
            <ul className="mx-auto">
              <li className="text-lg mb-4 text-gray-500">SOLUTION</li>
              <li className="mb-4 cursor-pointer">Business</li>
              <li className="mb-4 cursor-pointer">Marketing</li>
              <li className="mb-4 cursor-pointer">Analytics</li>
              <li className="mb-4 cursor-pointer">Internet of Things</li>
              <li className="mb-4 cursor-pointer">Security</li>
            </ul>
          </div>
          <div className="mx-4 flex items-baseline mt-2">
            <ul className="mx-auto">
              <li className="text-lg mb-4 text-gray-500">INDUSTRIES</li>
              <li className="mb-4">Education</li>
              <li className="mb-4">Communication, Media and Technologies</li>
              <li className="mb-4">Banking and Financial</li>
              <li className="mb-4">Healthcare</li>
              <li className="mb-4">Public services</li>
              <li className="mb-4">Travel, Transportation and hospitality</li>
            </ul>
          </div>

          <div className="mx-4 flex items-baseline mt-2">
            <ul className="mx-auto">
              <li className="text-lg mb-4 text-gray-500">COMPANY</li>
              <li className="mb-4 cursor-pointer">About</li>
              <li className="mb-4 cursor-pointer">Career</li>
              <li className="mb-4 cursor-pointer">Blog</li>
              <li className="mb-4 cursor-pointer">Press</li>
              <li className="mb-4 cursor-pointer">Partners</li>
            </ul>
          </div>
          <div className="mx-4 flex items-baseline mt-2">
            <ul className="mx-auto">
              <li className="text-lg mb-4 text-gray-500">LEGAL</li>
              <li className="mb-4 cursor-pointer">Claim</li>
              <li className="mb-4 cursor-pointer">Privacy Policy</li>
              <li className="mb-4 cursor-pointer">Terms</li>
            </ul>
          </div>

          <div className="mx-4 flex items-baseline mt-2">
            <ul className="mx-auto">
              <li className="text-lg mb-4 text-gray-500">CONTACT US</li>
              <li className="mb-4">Sahudangi Hat</li>
              <li className="mb-4">Kamat Para,Siliguri</li>
              <li className="mb-4">735132</li>
              <li className="mb-4">West Bengal (IN)</li>
              <li className="mb-4 hover:text-primary-500">
                <a href="mailto:hello@annwntech.com">hello@annwntech.com </a>
              </li>
              <li className="mb-4 hover:text-primary-500">
                <a href="tel:+917301437284"> +917301437284</a>{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap justify-between border-t w-full  mt-8 border-gray-700">
          <div className="md:my-8 my-4">
            <div className="text-gray-500 mb-2">
              SUBSCRIBE TO OUR NEWSLETTER
            </div>
            <div>
              The latest news, articles and resources send to your inbox weekly.
            </div>
          </div>
          <div className="flex flex-wrap md:space-x-2 my-4 items-center">
            <input
              type="text"
              className="bg-white border-none rounded text-gray-900 placeholder-gray-700 px-4 py-2 w-full md:w-auto"
              placeholder="johndoe@gmail.com"
            />
            <div className="px-4 py-2 rounded text-white bg-primary-500 mt-4 md:mt-0 cursor-pointer hover:bg-primary-600 focus:bg-primary-600 transition duration-300">
              Subscribe
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between border-t pt-2 border-gray-700">
          <div className="text-sm text-gray-700 w-full md:w-1/2">
            CIN:U72200WB2019PTC234217
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
